import React from "react";
import { Hero } from "nhsuk-react-components";
import { useLocation } from "react-router-dom";
import "../css/Common.css";

export default function Error() {
	const location = useLocation();

	return (
		<>
			<div className="container">
				<Hero>
					<Hero.Heading>Error</Hero.Heading>
					<Hero.Text>An error has occurred.</Hero.Text>
				</Hero>
				<div style={{ paddingTop: "24px", textAlign: "center" }}>
					<h4>
						<span id>{location.state.error}</span>
						<br />
						<br />
						Please inform{" "}
						<a href="mailto:matthew.reid8@nhs.net">
							Matthew Reid &lt;matthew.reid8@nhs.net&gt;
						</a>{" "}
						of this.
						<br />
						Thank you!
					</h4>
				</div>
			</div>
		</>
	);
}
