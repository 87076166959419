import React from "react";
import { Fieldset, Radios } from "nhsuk-react-components";
import Moment from "react-moment";
import "../css/Common.css";

export default function RecordDate(props) {
	const momentDate = props.date;
	const id = momentDate.format("DDMMYYYY");
	return (
		<Fieldset>
			<Fieldset.Legend style={{ fontWeight: "bold" }}>
				<Moment format="dddd, Do MMMM YYYY">{momentDate}</Moment>
			</Fieldset.Legend>
			<Radios id={`radios-${id}`}>
				<Radios.Radio value="LD">Long Day (07:00 - 19:30)</Radios.Radio>
				<Radios.Radio value="N">Night (19:00 - 07:30)</Radios.Radio>
				<Radios.Radio value="E">Early (07:00 - 15:00)</Radios.Radio>
				<Radios.Radio value="L">Late (11:30 - 19:30)</Radios.Radio>
				<Radios.Radio value="U">Not Available</Radios.Radio>
				<Radios.Radio value="O">Other</Radios.Radio>
			</Radios>
		</Fieldset>
	);
}
