import Header from "./components/Header";
import Footer from "./components/Footer";
import Availability from "./components/Availability";
import Error from "./components/Error";
import Done from "./components/Done";
import Home from "./components/Home";
import { Container } from "nhsuk-react-components";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

function App() {
	return (
		<Router>
			<Header />
			<Container style={{ maxWidth: "1500px" }}>
				<Switch>
					<Route exact path="/">
						<Home />
					</Route>
					<Route exact path="/done">
						<Done />
					</Route>
					<Route exact path="/error">
						<Error />
					</Route>
					<Route exact path="/record/:jwt">
						<Availability />
					</Route>
				</Switch>
			</Container>

			<Footer />
		</Router>
	);
}

export default App;
