import React from "react";
import { Hero } from "nhsuk-react-components";
import "../css/Common.css";

export default function Done() {
	return (
		<>
			<div className="container">
				<Hero>
					<Hero.Heading>Done!</Hero.Heading>
					<Hero.Text>Your availability has been recorded.</Hero.Text>
				</Hero>
				<div style={{ paddingTop: "24px", textAlign: "center" }}>
					<h4>
						Thank you for your submission. Your availability has been recorded.
						<br />
						Please continue to check Employee Online for your schedule.
					</h4>
				</div>
			</div>
		</>
	);
}
