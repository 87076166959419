import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";

var firebaseConfig = {
	apiKey: "AIzaSyAI6lTHLRSld8qP6qx4XiphyGv-Uv1BD_M",
	authDomain: "rsch-itu-availability.firebaseapp.com",
	projectId: "rsch-itu-availability",
	storageBucket: "rsch-itu-availability.appspot.com",
	messagingSenderId: "918950415203",
	appId: "1:918950415203:web:11b6141a8f4c5f56e5a156",
	measurementId: "G-YN9XZSP3KJ",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export { firebase };
