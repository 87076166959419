import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "nhsuk-react-components";
import { Router, useParams, useHistory } from "react-router-dom";
import moment from "moment";
import $ from "jquery";
import jwt_decode from "jwt-decode";
import { firebase } from "../services/firebase";
import RecordDates from "./RecordDates";
import "../css/Common.css";

export default function Availability() {
	const { jwt } = useParams();
	const [employee, setEmployee] = useState({});
	const history = useHistory();
	const functions = firebase.functions();
	const recordAvailability = functions.httpsCallable("recordAvailability");

	let token;

	try {
		token = jwt_decode(jwt);
	} catch (error) {
		console.log(error);
		alert("This link has expired!");
		// TODO: Sent to error page
	}

	useEffect(() => {
		setEmployee({
			name: token.name || "",
			role: token.role || "",
			department: token.department || "",
		});
	}, []);

	//const startDate = new Date(token.startDate) || new Date();
	const startDate = new Date();
	let days = [];
	let daysRequired = 8;

	for (let i = 0; i < daysRequired; i++) {
		days.push(moment(startDate).add(i, "days"));
	}

	const radioIds = days.map((day, id) => {
		return `radios-${day.format("DDMMYYYY")}`;
	});

	const half = Math.ceil(days.length / 2);

	const firstHalf = days.splice(0, half);
	const secondHalf = days.splice(-half);

	return (
		<>
			<div className="container">
				<div style={{ padding: "48px" }}>
					<Row>
						<h2>Record your availability for ITU</h2>
						<h5 style={{ fontWeight: "normal" }}>
							Please record any availability you have here. It is not guarenteed
							you will be working these shifts so you must check Employee Online
							to see when you are rostered on.
							<br />
							<br />
							If you cannot do any of the times listed below, please select
							"Other" and contact{" "}
							<a href="mailto:rsch-medphys.noreply@nhs.net">
								test-email@scicom.io
							</a>{" "}
							to let them know when you can work.
						</h5>
						<Col width="one-third">
							<Input
								label="Full Name"
								value={employee.name}
								onChange={(e) =>
									setEmployee((prevEmployee) => {
										return { ...prevEmployee, name: e.target.value };
									})
								}
							/>
							<Input
								label="Email"
								hint="Your NHS email address"
								disabled
								value={token.sub}
							/>
							<Input
								label="Job Title"
								hint="E.g. 'FY1 Doctor' or 'Band 6 Nurse'"
								value={employee.role}
								onChange={(e) =>
									setEmployee((prevEmployee) => {
										return { ...prevEmployee, role: e.target.value };
									})
								}
							/>
							<Input
								label="Normal Department"
								hint="If ITU is not your normal department, please fill it in here"
								value={employee.department}
								onChange={(e) =>
									setEmployee((prevEmployee) => {
										return { ...prevEmployee, department: e.target.value };
									})
								}
							/>
							<Button
								style={{ float: "right", marginTop: "12px", width: "100%" }}
								onClick={() => {
									let validated = true;

									for (const key in employee) {
										if (employee[key] == "") {
											alert("Please fill in all your information.");
											return;
										}
									}

									let dates = {};

									for (const id of radioIds) {
										const value = $(`input[name=${id}]:checked`).val();
										console.log(id);
										console.log(value);
										if (value == undefined) {
											validated = false;
											alert("Please fill in all the dates.");
											return;
										}

										const date = id.replace("radios-", "");
										dates[date] = value;
									}

									if (validated === true) {
										recordAvailability({
											token: jwt,
											availability: dates,
											user: employee,
										})
											.then((res) => {
												console.log(res);
												if (res.data.status == "complete") {
													history.push("/done");
												} else if (res.data.status == "error") {
													history.push({
														pathname: "/error",
														state: { error: res.data.msg },
													});
												}
											})
											.catch((err) => {
												history.push({
													pathname: "/error",
													state: { error: "An unknown error occured." },
												});
											});
									}
								}}
							>
								Submit
							</Button>
						</Col>
						<Col width="one-third">
							<RecordDates dates={firstHalf} />
						</Col>
						<Col width="one-third">
							<RecordDates dates={secondHalf} />
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}
