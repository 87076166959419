import React from "react";
import RecordDate from "./RecordDate";
import "../css/Common.css";

export default function RecordDates(props) {
	const recordDates = props.dates.map((date, id) => {
		return <RecordDate date={date} key={id} />;
	});
	return <>{recordDates}</>;
}
